import {
  GetStorygameSectionViewMoreQuery,
  IStorygameSectionViewMore,
  Section,
  SectionRankListType,
  SectionSliderType,
  STORY_GAME_SECTION_UI_TYPE,
  STORYGAME_SECTION_TYPE,
  StorygameSectionDataGameBasedRecommendation,
  StorygameSectionDataTagBasedRecommendation,
  StorygameSectionType,
  StorygameSectionUiType,
  StoryType,
} from '../types/storyGame'
import { getStringToDateByLanguage } from '../utils/getStringToDateByLanguage'
import { getCurrentLanguage } from '../utils/translate'
import { t } from '../utils/translate'

export interface IGetAllStoryGameSectionViewsQuery {
  getAllStorygameSectionViews: {
    sectionTitle?: string | null
    sectionId: number
    showReleaseDate: boolean
    hasViewMore: boolean
    isTestSection: boolean
    uiType: StorygameSectionUiType
    sectionData: string
    sectionType?: StorygameSectionType | null
  }[]
}

export const getFormattedStoryGameSectionViewData = (
  data: IGetAllStoryGameSectionViewsQuery
): Section[] => {
  const result: Section[] = []

  for (let i = 0; i < data.getAllStorygameSectionViews.length; i++) {
    const section = data.getAllStorygameSectionViews[i]
    if (section.uiType === STORY_GAME_SECTION_UI_TYPE.Slider) {
      const sectionData: SectionSliderType[] = JSON.parse(section.sectionData)
      result.push({
        sectionId: section.sectionId,
        title: section.sectionTitle || '',
        uiType: section.uiType,
        isTestSection: section.isTestSection,
        hasViewMore: section.hasViewMore,
        storyList: sectionData.map(data => {
          if (data.type === 'Link') {
            return {
              title: data.linkData?.title,
              subTitle: data.linkData?.subTitle,
              description: data.linkData?.content,
              mainImageLink: data.linkData?.imageFile?.link,
              bannerType: data.type,
              buttonName: data.linkData?.buttonName,
              url: data.linkData?.url,
              showReleaseDate: section.showReleaseDate,
              isAdult: data.story?.isAdult,
            }
          }
          const previews = data.story?.storygamePreviews?.map(preview => ({
            storyPreviewId: preview.storyPreviewId,
            link: preview.file?.link,
            fileType: preview.fileType || 'Image',
          }))
          if (data.story?.storygameCoverImageFile?.link) {
            previews?.unshift({
              storyPreviewId: 0,
              link: data.story?.storygameCoverImageFile?.link || '',
              fileType: 'Image',
            })
          }
          return {
            title: data.story?.name || '',
            subTitle: data.story?.storygameReleaseDate
              ? new Date() >= new Date(data.story.storygameReleaseDate)
                ? t('storygame_screen_label_release_date') +
                  ' : ' +
                  getStringToDateByLanguage(
                    getCurrentLanguage(),
                    data.story.storygameReleaseDate
                  ) // 출시일(Release Date) : 2021.01.01
                : getCurrentLanguage() === 'ko'
                ? getStringToDateByLanguage(
                    getCurrentLanguage(),
                    data.story.storygameReleaseDate
                  ) +
                  ' ' +
                  t('storygame_screen_label_available_date') // 2021.01.01 출시 예정
                : t('storygame_screen_label_available_date') +
                  ' ' +
                  getStringToDateByLanguage(
                    getCurrentLanguage(),
                    data.story.storygameReleaseDate
                  ) // Available 2021.01.01
              : '',
            description: data.story?.shortDesc || '',
            storyId: data.story?.storyId,
            mainImageLink: data.story?.storygameCoverImageFile?.link,
            previews,
            bannerType: data.type,
            buttonName: data.linkData?.buttonName,
            showReleaseDate: section.showReleaseDate,
            isAdult: data.story?.isAdult,
            bundleId: data.story?.bundleId,
          }
        }),
      })
      continue
    }

    if (section.uiType === STORY_GAME_SECTION_UI_TYPE.RankList) {
      const sectionData: SectionRankListType[] = JSON.parse(section.sectionData)

      result.push({
        sectionId: section.sectionId,
        title: section.sectionTitle || '',
        uiType: section.uiType,
        isTestSection: section.isTestSection,
        hasViewMore: section.hasViewMore,
        sectionList: sectionData.map(section => ({
          title: section.sectionTitle,
          storyList: section.stories
            ?.filter(story => !!story)
            .map(story => ({
              title: story?.name,
              storyId: story?.storyId,
              mainImageLink: story?.storygameCoverImageFile?.link,
              ranking: story.rank ?? 0,
              isAdult: story.isAdult,
              bundleId: story.bundleId,
            })),
          hasViewMore: !!section.viewMore,
        })),
      })
      continue
    }

    if (
      section.sectionType === STORYGAME_SECTION_TYPE.GameBasedRecommendation
    ) {
      const sectionData: StorygameSectionDataGameBasedRecommendation =
        JSON.parse(section.sectionData)
      result.push({
        sectionId: section.sectionId,
        title: section.sectionTitle || '',
        recommendedTargetText: sectionData.targetStoryName,
        isTestSection: section.isTestSection,
        hasViewMore: true,
        uiType: section.uiType,
        storyList: sectionData.stories.map(story => {
          return {
            title: story?.name,
            storyId: story?.storyId,
            mainImageLink: story?.storygameCoverImageFile?.link,
            showReleaseDate: section.showReleaseDate,
            isAdult: story.isAdult,
            subTitle:
              section.showReleaseDate && story?.storygameReleaseDate
                ? new Date() >= new Date(story.storygameReleaseDate)
                  ? t('storygame_screen_label_release_date') +
                    ' : ' +
                    getStringToDateByLanguage(
                      getCurrentLanguage(),
                      story.storygameReleaseDate
                    ) // 출시일(Release Date) : 2021.01.01
                  : getCurrentLanguage() === 'ko'
                  ? getStringToDateByLanguage(
                      getCurrentLanguage(),
                      story.storygameReleaseDate
                    ) +
                    ' ' +
                    t('storygame_screen_label_available_date') // 2021.01.01 출시 예정
                  : t('storygame_screen_label_available_date') +
                    ' ' +
                    getStringToDateByLanguage(
                      getCurrentLanguage(),
                      story.storygameReleaseDate
                    ) // Available 2021.01.01
                : '',
            ranking: story.rank ?? 0,
            chapterProgress: story.chapterProgress,
            bundleId: story.bundleId,
          }
        }),
      })
      continue
    }

    if (section.sectionType === STORYGAME_SECTION_TYPE.TagBasedRecommendation) {
      const sectionData: StorygameSectionDataTagBasedRecommendation[] =
        JSON.parse(section.sectionData)

      result.push(
        ...sectionData.map(tagBasedSectionItem => {
          return {
            sectionId: section.sectionId,
            title: section.sectionTitle || '',
            recommendedTargetText: tagBasedSectionItem.targetTagName,
            isTestSection: section.isTestSection,
            hasViewMore: true,
            uiType: section.uiType,
            storyList: tagBasedSectionItem.stories.map(story => {
              return {
                title: story?.name,
                storyId: story?.storyId,
                mainImageLink: story?.storygameCoverImageFile?.link,
                showReleaseDate: section.showReleaseDate,
                isAdult: story.isAdult,
                subTitle:
                  section.showReleaseDate && story?.storygameReleaseDate
                    ? new Date() >= new Date(story.storygameReleaseDate)
                      ? t('storygame_screen_label_release_date') +
                        ' : ' +
                        getStringToDateByLanguage(
                          getCurrentLanguage(),
                          story.storygameReleaseDate
                        ) // 출시일(Release Date) : 2021.01.01
                      : getCurrentLanguage() === 'ko'
                      ? getStringToDateByLanguage(
                          getCurrentLanguage(),
                          story.storygameReleaseDate
                        ) +
                        ' ' +
                        t('storygame_screen_label_available_date') // 2021.01.01 출시 예정
                      : t('storygame_screen_label_available_date') +
                        ' ' +
                        getStringToDateByLanguage(
                          getCurrentLanguage(),
                          story.storygameReleaseDate
                        ) // Available 2021.01.01
                    : '',
                ranking: story.rank ?? 0,
                chapterProgress: story.chapterProgress,
                bundleId: story.bundleId,
              }
            }),
          }
        })
      )
      continue
    }

    // 캐로셀 형태 섹션 데이터
    const sectionData: StoryType[] = JSON.parse(section.sectionData)

    result.push({
      sectionId: section.sectionId,
      title: section.sectionTitle || '',
      isTestSection: section.isTestSection,
      hasViewMore: section.hasViewMore,
      uiType: section.uiType,
      storyList: sectionData.map((story, index) => ({
        title: story?.name,
        storyId: story?.storyId,
        mainImageLink: story?.storygameCoverImageFile?.link,
        showReleaseDate: section.showReleaseDate,
        isAdult: story.isAdult,
        subTitle:
          section.showReleaseDate && story?.storygameReleaseDate
            ? new Date() >= new Date(story.storygameReleaseDate)
              ? t('storygame_screen_label_release_date') +
                ' : ' +
                getStringToDateByLanguage(
                  getCurrentLanguage(),
                  story.storygameReleaseDate
                ) // 출시일(Release Date) : 2021.01.01
              : getCurrentLanguage() === 'ko'
              ? getStringToDateByLanguage(
                  getCurrentLanguage(),
                  story.storygameReleaseDate
                ) +
                ' ' +
                t('storygame_screen_label_available_date') // 2021.01.01 출시 예정
              : t('storygame_screen_label_available_date') +
                ' ' +
                getStringToDateByLanguage(
                  getCurrentLanguage(),
                  story.storygameReleaseDate
                ) // Available 2021.01.01
            : '',
        ranking: index + 1,
        chapterProgress: story.chapterProgress,
        bundleId: story.bundleId,
      })),
    })
  }

  return result
}

export const getFormattedStoryGameSectionViewMoreData = (
  data: GetStorygameSectionViewMoreQuery
): IStorygameSectionViewMore => {
  const section = data.getStorygameSectionViewMore?.sectionView

  if (section?.uiType === 'RankList') {
    const sectionData: SectionRankListType[] = JSON.parse(
      section?.sectionData || '[]'
    )
    return {
      title: sectionData[0].sectionTitle || '',
      totalCount: 0,
      hasRanking: true,
      storyList: sectionData[0].stories?.map((story, index) => ({
        storyId: story.storyId || 0,
        coverImageUrl: story.storygameCoverImageFile?.link || '',
        title: story.name || '',
        subTitle: '',
        isAdult: !!story.isAdult,
        ranking: index + 1,
        tag: story.storyTags || [],
        myFavorite: !!story.myFavorite,
        releaseDate: story.storygameReleaseDate || new Date(),
        showReleaseDate: section?.showReleaseDate,
        bundleId: story.bundleId,
      })),
    }
  }

  if (section?.sectionType === STORYGAME_SECTION_TYPE.TagBasedRecommendation) {
    const sectionData: StorygameSectionDataTagBasedRecommendation[] =
      JSON.parse(section?.sectionData || '[]')

    return {
      title: section?.sectionTitle || '',
      recommendedTargetText: sectionData[0].targetTagName || '동물',
      totalCount: sectionData[0].totalCount,
      hasRanking: false,
      storyList: sectionData[0].stories.map((story, index) => ({
        storyId: story.storyId || 0,
        coverImageUrl: story.storygameCoverImageFile?.link || '',
        title: story.name || '',
        subTitle: '',
        isAdult: !!story.isAdult,
        ranking: index + 1,
        tag: story.storyTags || [],
        myFavorite: !!story.myFavorite,
        releaseDate: story.storygameReleaseDate || new Date(),
        showReleaseDate: section?.showReleaseDate,
        bundleId: story.bundleId,
      })),
    }
  }

  if (section?.sectionType === STORYGAME_SECTION_TYPE.GameBasedRecommendation) {
    const sectionData: StorygameSectionDataGameBasedRecommendation = JSON.parse(
      section?.sectionData || '{}'
    )
    return {
      title: section?.sectionTitle || '',
      recommendedTargetText: sectionData.targetStoryName || '',
      totalCount: sectionData.stories.length,
      hasRanking: false,
      storyList: sectionData.stories.map((story, index) => ({
        storyId: story.storyId || 0,
        coverImageUrl: story.storygameCoverImageFile?.link || '',
        title: story.name || '',
        subTitle: '',
        isAdult: !!story.isAdult,
        ranking: index + 1,
        tag: story.storyTags || [],
        myFavorite: !!story.myFavorite,
        releaseDate: story.storygameReleaseDate || new Date(),
        showReleaseDate: section?.showReleaseDate,
        bundleId: story.bundleId,
      })),
    }
  }

  const sectionData: StoryType[] = JSON.parse(section?.sectionData || '[]')
  return {
    title: data.getStorygameSectionViewMore?.sectionView?.sectionTitle || '',
    totalCount: data.getStorygameSectionViewMore?.totalCount || 0,
    hasRanking: false,
    storyList: sectionData.map((story, index) => ({
      storyId: story.storyId || 0,
      coverImageUrl: story.storygameCoverImageFile?.link || '',
      title: story.name || '',
      subTitle: '',
      isAdult: !!story.isAdult,
      ranking: index + 1,
      tag: story.storyTags || [],
      myFavorite: !!story.myFavorite,
      releaseDate: story.storygameReleaseDate || new Date(),
      showReleaseDate: section?.showReleaseDate,
      bundleId: story.bundleId,
    })),
  }
}
